import { ajax, ajaxHeader } from './helper'
import * as Constants from "./constants";

class ApiService {
  async onSignUpSubmit(body) {
    const onSignUpSubmitUrl = 'v1/user/signup'
    return await ajax(onSignUpSubmitUrl, 'POST', body)
  }

  async applyCoupon(body) {
    const applyCouponUrl = 'v1/coupon/apply'
    return await ajax(applyCouponUrl, 'POST', body)
  }

  async publicInstantSearch(body) {
    const instantSearchUrl = '/public/verify'
    return await ajax(instantSearchUrl, 'POST', body)
  }

  async instantSearch(body) {
    const instantSearchUrl = 'v1/email_verify/instant'
    return await ajax(instantSearchUrl, 'POST', body)
  }

  async verifyToken(body) {
    const verifyTokenUrl = 'v1/user/verify_token'
    return await ajax(verifyTokenUrl, 'POST', body)
  }

  async signUpUpdate(body) {
    const signUpUpdateUrl = 'v1/user/activate'
    return await ajax(signUpUpdateUrl, 'POST', body)
  }

  async forgotPassword(body, token) {
    const forgotPasswordUrl = 'v1/user/forgot-password'
    return await ajax(forgotPasswordUrl, 'POST', body, true, token, false)
  }

  async resetPassword(body, token) {
    const resetPasswordUrl = 'v1/user/reset-password'
    return await ajax(resetPasswordUrl, 'POST', body, true, token, false)
  }

  async login(body) {
    const loginUrl = 'v1/user/login'
    return await ajax(loginUrl, 'POST', body)
  }

  async mailChimpAPI() {
    const mailChimpAPIURL = 'oauth/mailchimp'
    return await ajax(mailChimpAPIURL, 'GET')
  }

  async mailChimpAccounts() {
    const mailChimpAccountsUrl = 'v1/external/mailchimp/accounts'
    return await ajax(mailChimpAccountsUrl, 'GET')
  }

  async getStatusOfIntegratedAccounts() {
    const getStatusOfMailChimpUrl = 'v1/external/linked_accounts'
    return await ajax(getStatusOfMailChimpUrl, 'GET')
  }

  async mailChimpListByAccount(account_id, body) {
    const mailChimpListByAccountUrl = 'v1/external/mailchimp/' + account_id + '/list'
    return await ajax(mailChimpListByAccountUrl, 'POST', body)
  }

  async mailChimpListAddtoMyLists(account_id, body) {
    const mailChimpListAddtoMyListsUrl = 'v1/external/mailchimp/' + account_id + '/add_list'
    return await ajax(mailChimpListAddtoMyListsUrl, 'POST', body)
  }

  async logout() {
    const logoutUrl = 'v1/user/logout'
    return await ajax(logoutUrl, 'GET')
  }

  async editEmailAddress(body) {
    const editEmailAddressUrl = 'v1/user/email/edit'
    return await ajax(editEmailAddressUrl, 'POST', body)
  }

  async editAddress(body) {
    const editAddressUrl = "v1/user/address/edit";
    return await ajax(editAddressUrl, "POST", body);
  }

  async createSetupIntent(body) {
    const createSetupIntentUrl = "v1/payment/setup_intent/create";
    return await ajax(createSetupIntentUrl, "POST", body);
  }

  async getAnnouncementBarText() {
    const announcementBarTextUrl = 'v1/app/announcement'
    return await ajax(announcementBarTextUrl, 'GET')
  }

  async editUsername(body) {
    const editUsernameUrl = 'v1/user/name/edit'
    return await ajax(editUsernameUrl, 'POST', body)
  }

  async updateEmailAddress(body) {
    const updateEmailAddressUrl = 'v1/user/email/update'
    return await ajax(updateEmailAddressUrl, 'POST', body)
  }

  async updateFreshchatRestoreId(body) {
    const updateFreshchatResctoreIdUrl = "v1/user/freshchat/update"
    return await ajax(updateFreshchatResctoreIdUrl, 'POST', body)
  }

  async updateUserTimeZone(body) {
    const updateUserTimeZoneUrl = 'v1/user/timezone/edit'
    return await ajax(updateUserTimeZoneUrl, 'POST', body)
  }

  async changeUserPassword(body) {
    const changeUserPasswordUrl = 'v1/user/password/edit'
    return await ajax(changeUserPasswordUrl, 'POST', body)
  }

  async fetchAllList(body) {
    const fetchAllListUrl = 'v1/list'
    return await ajax(fetchAllListUrl, 'POST', body)
  }

  async insightsGraph(body) {
    const insightsSummaryUrl = 'v1/insights/graph'
    return await ajax(insightsSummaryUrl, 'POST', body)
  }

  async profile() {
    const profileUrl = 'v1/user/profile'
    return await ajax(profileUrl, 'GET')
  }

  async userPreference(body) {
    const userPreferenceUrl = 'v1/user/preferences/notification'
    return await ajax(userPreferenceUrl, 'POST', body)
  }

  async chunkFileUpload() {
    const chunkFileUploadUrl = 'v1/phonenumber/bulk_upload'
    return await ajax(chunkFileUploadUrl, 'POST')
  }

  async generateApiKey() {
    const generateApiKeyUrl = 'v1/user/generateapikey'
    return await ajax(generateApiKeyUrl, 'GET')
  }

  // payment
  async changeDefaultCard(body) {
    const changeDefaultCardUrl = 'v1/payment/card/default'
    return await ajax(changeDefaultCardUrl, 'POST', body)
  }

  async getDefaultCard() {
    const getDefaultCardUrl = 'v1/payment/defaultcard'
    return await ajax(getDefaultCardUrl, 'POST')
  }

  async deleteCard(body) {
    const deleteCardUrl = 'v1/payment/card/delete'
    return await ajax(deleteCardUrl, 'POST', body)
  }

  async addCard(body) {
    const deleteCardUrl = 'v1/payment/card/add'
    return await ajax(deleteCardUrl, 'POST', body)
  }

  async listCards() {
    const listCardsUrl = 'v1/payment/cards'
    return await ajax(listCardsUrl, 'GET')
  }

  async listInvoices() {
    const listInvoicesUrl = 'v1/invoice/list'
    return await ajax(listInvoicesUrl, 'GET')
  }

  async listRecipt() {
    const listReciptUrl = 'v1/receipt/list'
    return await ajax(listReciptUrl, 'GET')
  }

  // payment apis
  async buy(body) {
    const buyUrl = 'v1/payment/buy'
    return await ajax(buyUrl, "POST", body)
  }

  async confirm(body) {
    const confirmUrl = 'v1/payment/confirm'
    return await ajax(confirmUrl, "POST", body)
  }

  //credit
  async buyCredits(body) {
    const buyCreditsUrl = 'v1/payment/buy'
    return await ajax(buyCreditsUrl, 'POST', body)
  }

  async listSlabs() {
    const slabsUrl = 'v1/price/slab'
    return await ajax(slabsUrl, 'GET')
  }

  async priceCalc(credits, cancelToken) {
    const priceCalcUrl = 'v1/price?credits=' + credits
    return await ajax(priceCalcUrl, 'GET', null, true, cancelToken)
  }

  async downloadResultFile(body) {
    const downloadResultFileUrl = 'v1/download/result'
    return await ajax(downloadResultFileUrl, 'POST', body)
  }

  async checkUserCreditsStatus() {
    let userCreditStatusUrl = 'v1/user/check_credit_status'
    return ajax(userCreditStatusUrl, 'GET')
  }

  async getRemainingCredits() {
    let getRemainingCreditsUrl = 'v1/phonenumber/getcredits'
    return ajax(getRemainingCreditsUrl, 'GET')
  }

  async marketingStats() {
    let marketingStatsUrl = '/public/marketing_stats'
    return ajax(marketingStatsUrl, 'GET')
  }

  async searchLeads(body) {
    let searchLeadsUrl = 'v1/leads'
    return ajax(searchLeadsUrl, 'POST', body)
  }

  async createLeadsList(body) {
    let createLeadsListUrl = 'v1/leads/create'
    return ajax(createLeadsListUrl, 'POST', body)
  }

  async getList(body, cancelToken) {
    const getJobsListUrl = 'v1/list'
    return await ajax(getJobsListUrl, 'POST', body, true, cancelToken)
  }

  async verifyList(body) {
    const verifyListUrl = 'v1/list/validate'
    body['source_agent'] = Constants.USER_SOURCE.INSTANT_PHONENUMBER_VALIDATE
    return await ajax(verifyListUrl, 'POST', body)
  }

  async exportList(body) {
    const exportListUrl = 'v1/list/export'
    return await ajax(exportListUrl, 'POST', body)
  }

  async removeInputFile(body) {
    const removeInputFileUrl = 'v1/list/remove'
    return await ajax(removeInputFileUrl, 'POST', body)
  }

  //admin api's
  async addUsers(body) {
    const addUsersUrl = 'v1/admin/user/add'
    return await ajax(addUsersUrl, 'POST', body)
  }

  async editUsers(body) {
    const editUsersUrl = 'v1/admin/user/edit'
    return await ajax(editUsersUrl, 'POST', body)
  }

  async removeUsers(body) {
    const removeUsersUrl = 'v1/admin/user/remove'
    return await ajax(removeUsersUrl, 'POST', body)
  }

  async allUsersList(body) {
    const addUsersUrl = 'v1/admin/user/list'
    return await ajax(addUsersUrl, 'POST', body)
  }

  async addTeam(body) {
    const addTeamUrl = 'v1/admin/team/create'
    return await ajax(addTeamUrl, 'POST', body)
  }

  async allTeamList(body) {
    const allTeamListUrl = 'v1/admin/team/list'
    return await ajax(allTeamListUrl, 'POST', body)
  }

  async individualTeamList(body) {
    const allTeamListUrl = 'v1/admin/team/users'
    return await ajax(allTeamListUrl, 'POST', body)
  }

  async editTeam(body) {
    const createTeamUrl = 'v1/admin/team/edit'
    return await ajax(createTeamUrl, 'POST', body)
  }

  async editOrgDetails(body) {
    const editOrgDetailsUrl = 'v1/admin/edit'
    return await ajax(editOrgDetailsUrl, 'POST', body)
  }

  async getPerformanceStats(body) {
    const getPerformanceStatsUrl = 'v1/insights/performance_stats'
    return await ajax(getPerformanceStatsUrl, 'POST', body)
  }

  //active campaign
  async activeCampaignAccounts() {
    const activeCampaignAccountsUrl = 'v1/external/active_campaign/accounts'
    return await ajax(activeCampaignAccountsUrl, 'GET')
  }

  async activeCampaignListByAccount(account_id, body) {
    const activeCampaignListByAccountUrl = 'v1/external/active_campaign/account/' + account_id + '/lists'
    return await ajax(activeCampaignListByAccountUrl, 'POST', body)
  }

  async activeCampaignListAddtoMyLists(account_id, body) {
    const mailChimpListaAddtoMyListsUrl = 'v1/external/active_campaign/' + account_id + '/add_list'
    return await ajax(mailChimpListaAddtoMyListsUrl, 'POST', body)
  }

  async addActiveCampaignAccount(body) {
    const addActiveCampaignAccountUrl = 'v1/external/active_campaign/account/add'
    return await ajax(addActiveCampaignAccountUrl, 'POST', body)
  }

  //moosend
  async addMoosendAccount(body) {
    const moosendAddAccountsUrl = 'v1/external/moosend/account/add'
    return await ajax(moosendAddAccountsUrl, 'POST', body)
  }

  async getMoosendAccounts() {
    const moosendAddAccountsUrl = 'v1/external/moosend/accounts'
    return await ajax(moosendAddAccountsUrl, 'GET')
  }

  async getMoosendAccountList(account_id) {
    const getMoosendAccountListUrl = 'v1/external/moosend/account/' + account_id + '/lists'
    return await ajax(getMoosendAccountListUrl, 'POST')
  }

  async addToMyListMoosend(account_id, body) {
    const addToMyListMoosendUrl = 'v1/external/moosend/' + account_id + '/add_list'
    return await ajax(addToMyListMoosendUrl, 'POST', body)
  }

  // hubspot
  async hubspotAccounts() {
    const hubspotAccountsUrl = 'v1/external/hubspot/accounts'
    return await ajax(hubspotAccountsUrl, 'GET')
  }

  async hubspotListAddToMyLists(account_id, body) {
    const hubspotListAddToMyListsUrl = 'v1/external/hubspot/' + account_id + '/add_list'
    return await ajax(hubspotListAddToMyListsUrl, 'POST', body)
  }

  async hubspotListByAccount(account_id, body) {
    const hubspotListByAccountUrl = 'v1/external/hubspot/account/' + account_id + '/lists'
    return await ajax(hubspotListByAccountUrl, 'POST', body)
  }

  async createPaypalOrder(credits) {
    const createPaypalOrderUrl = 'v1/payment/paypal/order/create'
    return await ajax(createPaypalOrderUrl, 'POST', credits)
  }

  async verifyPaypalOrder(body) {
    const verifyPaypalOrderUrl = 'v1/payment/paypal/order/verify'
    return await ajax(verifyPaypalOrderUrl, 'POST', body)
  }

  async capturePaypalOrder(body) {
    const capturePaypalOrderUrl = 'v1/payment/paypal/order/capture'
    return await ajax(capturePaypalOrderUrl, 'POST', body)
  }

  async adminLowCreditUpdate(body) {
    const lowCreditUpdateUrl = 'v1/admin/low_credit_alert/update'
    return await ajax(lowCreditUpdateUrl, 'POST', body)
  }

  async userLowCreditUpdate(body) {
    const lowCreditUpdateUrl = 'v1/user/low_credit_alert/update'
    return await ajax(lowCreditUpdateUrl, 'POST', body)
  }

  async userUpgradeAccount(body) {
    const userUpgradeAccountUrl = 'v1/user/upgrade'
    return await ajax(userUpgradeAccountUrl, 'POST', body)
  }

  async validatePhoneNumber(body) {
    body['source_agent'] = Constants.USER_SOURCE.INSTANT_PHONENUMBER_VALIDATE
    const validatePhoneNumberUrl = 'v1/phonenumber/validate'
    return await ajaxHeader(validatePhoneNumberUrl, 'POST', body)
  }

  async validatePublicApiPhoneNumber(body, token) {
    const validatePublicApiPhoneNumberUrl = 'public/phonenumber_validate'
    return await ajax(validatePublicApiPhoneNumberUrl, 'POST', body, true, token, false)
  }

  async checkIpLocation(token) {
    const checkIpLocationUrl = 'public/ip_location'
    return await ajax(checkIpLocationUrl, 'GET', null, true, token, false)
  }

  //subscription
  async getSubscriptionPlans(queryParams, isPublic) {
    let appendAuth = isPublic ? false : true
    const subscriptionPlansUrl = 'v1/price/products' + queryParams
    return await ajax(subscriptionPlansUrl, 'GET', null, true, null, appendAuth)
  }

  // async getSubscriptionPlans(){
  //   const subscriptionPlansUrl = 'v1/price/plan'
  //   return await ajax(subscriptionPlansUrl, 'GET')
  // }

  async upgrade(queryParams) {
    const upgradeUrl = 'v1/payment/upgrade?' + queryParams
    return await ajax(upgradeUrl, "GET")
  }

  async cancel(body) {
    const cancelUrl = 'v1/payment/cancel'
    return await ajax(cancelUrl, "PATCH", body)
  }

  async createSubcription(body) {
    const createSubsUrl = "v1/payment/paypal/create/subscription"
    return await ajax(createSubsUrl, "POST", body)
  }

  async checkSubscription(body) {
    const subscribeUrl = 'v1/payment/paypal/plan/subscription'
    return await ajax(subscribeUrl, 'POST', body)
  }

  async createSubs(body) {
    const createSubsUrl = 'v1/payment/paypal/create/subscription'
    return await ajax(createSubsUrl, 'POST', body)
  }

  async updateSubscribe(body) {
    const subscribeUrl = 'v1/payment/paypal/subscription'
    return await ajax(subscribeUrl, 'POST', body)
  }

  async cancelSubscription(body) {
    const cancelSubscriptionUrl = 'v1/payment/subscription/cancel?reason=' + body
    return await ajax(cancelSubscriptionUrl, 'GET')
  }

  async currentPlanInfo() {
    const currentPlanInfoUrl = 'v1/user/current_plan'
    return await ajax(currentPlanInfoUrl, 'GET')
  }

  async userActivities(body) {
    const userActivitiesUrl = 'v1/user/activities'
    return await ajax(userActivitiesUrl, 'POST', body)
  }

  async confirmSetupIntent(body) {
    const confirmSetupIntentUrl = 'v1/payment/setup_intent/confirm'
    return await ajax(confirmSetupIntentUrl, "POST", body);
  }
}

export default new ApiService()
