import React, { useReducer } from "react"
import _ from 'lodash'
import * as constants from './../helpers/constants'

const modals = {
  modals: [{
    name: constants.ALERT_MODAL,
    priority: constants.ALERT_MODAL_PRIORITY
  }, {
    name: constants.VERSION_MODAL,
    priority: constants.VERSION_MODAL_PRIORITY
  }, {
    name: constants.CREDIT_MODAL,
    priority: constants.CREDIT_MODAL_PRIORITY
  }]
}

const initState = {
  name: '',
  isOpen: false,
  params: {}
}

const findModal = (activeModal) => {
  let modal = _.find(modals.modals,
    function (obj) {
      if (obj.name == activeModal) {
        return obj;
      }
    })
  return modal
}

const modalReducer = (state, action) => {

  if (action.isOpen) {
    let modal = findModal(action.name)
    if (modal) {

      if (action.forceOpen) {
        return {
          name: action.name,
          isOpen: action.isOpen,
          params: action.params ? action.params : initState.params
        }
      }

      let currentModal = (state && state.name) ? findModal(state.name) : null
      if (currentModal) {
        if (modal.priority >= currentModal.priority) {
          return {
            name: action.name,
            isOpen: action.isOpen,
            params: action.params ? action.params : initState.params
          }
        } else {
          return {
            name: state.name,
            isOpen: state.isOpen,
            params: state.params ? state.params : initState.params
          }
        }
      } else {
        if (modal.priority > 0) {
          return {
            name: action.name,
            isOpen: action.isOpen,
            params: action.params ? action.params : initState.params
          }
        }
      }
    }
  }
  return initState
}

export const ModalControllerContext = React.createContext({
  modalState: {},
  dispatchModal: () => { }
})


const ModalControllerContextProvider = (props) => {

  const [modalState, dispatchModalHandler] = useReducer(modalReducer,
    initState)

  return (
    <ModalControllerContext.Provider
      value={{ modalState: modalState, dispatchModal: dispatchModalHandler }}>
      {props.children}
    </ModalControllerContext.Provider>
  )
}

export default ModalControllerContextProvider