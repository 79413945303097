import React, { Component } from 'react'
import { Navbar, NavItem, Collapse, NavbarToggler } from 'reactstrap'
import { NavLink } from 'react-router-dom'

import appEventEmitter, { AppEvents } from '../../helpers/AppEventEmitter'
import ApiService from '../../helpers/apiservice'
import * as Constants from '../../helpers/constants'
import * as Helpers from '../../helpers/helper'

import { isMobile } from "react-device-detect"
import AnnounceKit from 'announcekit-react';
import ReactTooltip from 'react-tooltip'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      collapsed: true
    }
    this.menuDropdownRef = null;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    if (window) {
      if (!window.clearoutphone) {
        window.clearoutphone = []
      }
      window.clearoutphone["header"] = this

      if (typeof window.clearoutphone.signOutClick !== "function") {
        window.clearoutphone.signOutClick = this.signOutClick;
      }

    } else {
      console.log("Error: window object not found, app not running in brwoser environment")
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      collapsed: !this.state.collapsed
    })
  }

  /*handleClickOutside = (e) => {
    if (this.menuDropdownRef && !this.menuDropdownRef.contains(e.target)) {
      if (!this.state.collapsed) {
        this.toggle()
      }
    }
  }*/

  closeNavbar = () => {
    if (this.state.collapsed != true) {
      this.toggle()
    }
  }

  signOutClick = () => {
    appEventEmitter.emit(AppEvents.user_clicked_logout_btn, { app_version: APP_VERSION })
    if (window.fcWidget) {
      window.fcWidget.user.get((res) => {
        if (res.success) {
          window.fcWidget.user.clear()
        }
      })
    }

    ApiService.logout().then((res) => {
      if (res.status == Constants.CO_API.Success) {
        Helpers.clearLocalStorage()
        window.location.reload()
        appEventEmitter.emit(AppEvents.user_signout_success, { app_version: APP_VERSION })

      }
    }).catch((err) => {
      console.log('Logout api erred', err)
      Helpers.clearLocalStorage()
      window.location.reload()
    })
  }

  getWidgetButton = () => {
    return (
      <a className="header-item ml-auto" onClick={() => this.props.openAnnounceKitWidget()}>
        <AnnounceKit ref={this.props.widgetAnnounceKitRef}
          widget={ANNOUNCEKIT_TOKEN}
          widgetStyle={{
            borderRadius: "50%",
            fontSize: "1em",
            marginLeft: "-7px",
            marginTop: "-15px",
            textAlign: "center",
            lineHeight: "1.7rem"
          }}
        >
          <span data-tip data-for="widget">
            <i className="fa fa-bell-o"></i>
          </span>
          {isMobile ? null :
            <ReactTooltip className="custom-header-tooltip" id="widget" place="bottom" multiline={true}>
              <span>{"What's new?"}</span>
            </ReactTooltip>}
        </AnnounceKit>
      </a>

    )
  }

  getHelpButton = () => {
    return (
      <a
        href="https://clearoutphone.io/help/"
        target='_blank'
        rel="noopener noreferrer"
      >
        <span data-tip data-for="help">
          {isMobile ? "Help" :
            <i className="fa fa-question-circle-o" style={{ fontSize: '19px', marginTop: '5px', marginLeft: "-10px" }}></i>}
        </span>
        {isMobile ? null :
          <ReactTooltip className="custom-header-tooltip" id="help" place="bottom" multiline={true}>
            <span>Help</span>
          </ReactTooltip>}
      </a>
    )
  }

  render() {
    // let home = require('../../static/svg/home.svg')
    return (
      <div className="main-header">
        <Navbar expand="md" className="container">
          <NavLink to="/dashboard" className="navbar-brand">
            <img className="logo-header" src={require('../../static/img/clearout-phone-logo-final-white-200x40.png')} alt="logo" />
          </NavLink>
          {isMobile ? this.getWidgetButton() : null}
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <ul className="ml-auto navbar-nav" ref={(input) => { this.menuDropdownRef = input; }}>
              <NavItem>
                <NavLink
                  activeClassName="active"
                  to={'/dashboard/overview'}
                  onClick={this.closeNavbar}
                >
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to={'/dashboard/pricing'}
                  activeClassName="active"
                  data-tip data-for="buy-credits-tooltip"
                  onClick={this.closeNavbar}>
                  Buy Credits
                </NavLink>
                {isMobile ? null :
                  <ReactTooltip className="custom-header-tooltip" id="buy-credits-tooltip" place="bottom" multiline={true}>
                    <span>{Constants.HEADER_SUBS_PRICING_DISCOUNT_TEXT}</span>
                  </ReactTooltip>}
              </NavItem>
              {/* <NavItem>
                <NavLink 
                  to={'/dashboard/leads'} 
                  activeClassName="active" 
                  onClick={this.closeNavbar}
                  style={{position: 'relative'}}
                >
                  Lead Finder
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  to={'/dashboard/integrations'}
                  activeClassName="active"
                  data-tip data-for="integrations-tooltip"
                  onClick={this.closeNavbar}>
                  Integrations
                </NavLink>
                {isMobile ? null :
                  <ReactTooltip className="custom-header-tooltip" id="integrations-tooltip" place="bottom" multiline={true}>
                    <span>Connect your favourite Apps with ClearoutPhone</span>
                  </ReactTooltip>}
              </NavItem>
              {/* <NavItem>
                <NavLink 
                  to={'/dashboard/account'} 
                  activeClassName="active" 
                  onClick={this.closeNavbar}
                  title={'Account'}
                >
                 
                  {isMobile ? 'Profile' :  <i className="fa fa-user"></i>}
                </NavLink>
              </NavItem> */}
              {/* <NavItem>
                <a target="blank" title="Help" href={Constants.CLEAROUTPHONE_WEBSITE.HELP}>
                  <i className="fa fa-question-circle-o" style={{fontSize: '21px'}}></i>
                </a>
              </NavItem> */}
              {isMobile ? null
                :
                <NavItem>
                  {this.getWidgetButton()}
                </NavItem>}
              <NavItem>
                {this.getHelpButton()}
              </NavItem>

            </ul>
          </Collapse>
        </Navbar>
      </div>
    )
  }
}

export function NavHeader() {
  return window.clearoutphone["header"]
}

export default Header