import appEventEmitter, { AppEvents } from '../../helpers/AppEventEmitter'

const LOGIN_SUCCESS = 'login-success'
const Login_REQUEST_STARTED = 'login-request-started'
const LOGIN_FAILED = 'login-failed'
const LOGOUT_SUCCESS = "logout-success"
const UPDATE_USER_EMAIL = 'update-email-address'
const UPDATE_CREDITS = 'update-credits'
const UPDATE_USER_NAME = 'update-username'
const UPDATE_LOCALE = 'update-locale'
const UPDATE_VALID_CARD = 'update-valid-card'
const UPDATE_USER_LIMITS = 'update-user-limits'
const UPDATE_NOTIFICATION_SETTINGS = 'update-notification-settings'
const UPDATE_LOW_CREDIT_NOTIFICATION_SETTINGS = 'update-low-credit-notification-settings'
const UPDATE_INTEGRATION_STATUS = 'update-integration-status'
const UPDATE_RECCUR_CREDITS = 'update-reccuring-credits'
const UPDATE_USER = 'update-user'
const UPDATE_CURRENT_PLAN = 'update-current-plan'
const SHOW_STRIPE = 's-s'
const UPDATE_USER_ADDRESS = 'update-address'

const initialState = {
  id: 0,
  email: '',
  name: 'User',
  isLogged: false,
  integrations: {},
  address: {},
  user_role: '',
  jwtToken: null,
  restoreId:null,
  isLoginSuccess: false,
  isEmailVerified: false,
  credits: null,
  isValidCard: false,
  limits : {},
  recur : {},
  notifications : {},
  low_credit_notification: {},
  current_plan : {}

}


export default function reducer(state = {...initialState}, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        address: action.payload.address ? action.payload.address : {},
        jwtToken: action.payload.token,
        isLoginSuccess: true,
        restoreId:action.payload.fresh_chat,
        integrations: action.payload.integrations,
        user_role: action.payload.user_role,
        payment: action.payload.payment ? action.payload.payment : {},
        isLogged: true,
        isEmailVerified: action.payload.email_verified,
        credits: action.payload.credits,
        locale: action.payload.locale,
        isValidCard: action.payload.is_valid_card ? action.payload.is_valid_card : false,
        current_plan: action.payload.subscription ? action.payload.subscription : []

      })

    case Login_REQUEST_STARTED:
      return Object.assign({}, state, { isLogged: true })

    case LOGIN_FAILED:
      return Object.assign({}, state, initialState)

 
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, initialState)

    case UPDATE_USER_EMAIL:
      return Object.assign({}, state, {
        email: action.payload,
      })

    case UPDATE_USER_ADDRESS:
      return Object.assign({}, state, {
        address: action.payload,
      })

    case UPDATE_LOW_CREDIT_NOTIFICATION_SETTINGS:
      return Object.assign({}, state, {
        low_credit_notification: action.payload
      })

    case UPDATE_CREDITS:
      return Object.assign({}, state, {
        credits: action.payload,
      })

    case UPDATE_USER_NAME: 
      return Object.assign({}, state, {
        name: action.payload,
      })

    case SHOW_STRIPE:
      return Object.assign({}, state, {
        sS: action.payload
      })
    
    case UPDATE_LOCALE: 
      return Object.assign({}, state, {
        locale: action.payload,
      })

    case UPDATE_VALID_CARD: 
      return Object.assign({}, state, {
        payment: {
          ...state.payment,
          stripe: {
            ...state.payment.stripe,
            is_valid_card: action.payload
          }
        }
      })
    case UPDATE_USER_LIMITS:
      return Object.assign({}, state,{
        limits:action.payload
      })

    case UPDATE_RECCUR_CREDITS:
      return Object.assign({}, state,{
        recur:action.payload
      })

    case UPDATE_NOTIFICATION_SETTINGS:
      return Object.assign({}, state,{
        notifications:action.payload
      })

    case UPDATE_INTEGRATION_STATUS:
      return Object.assign({}, state,{
        integrations:action.payload
      })

    case UPDATE_USER:
      return Object.assign({}, state, { ...action.payload })

    case UPDATE_CURRENT_PLAN:
      return Object.assign({}, state,{
        current_plan:action.payload
      })
      
    default:
      return state
  }
}

export function loginSuccess(payload) {
  appEventEmitter.emit(AppEvents.user_login_success, { ...payload, app_version: APP_VERSION })
  return { type: LOGIN_SUCCESS, payload }
}

export function loginFailed(payload) {
  return { type: LOGIN_FAILED, payload }
}

export function logoutSuccess(payload) {
  return {
    type: LOGOUT_SUCCESS,
    payload
  }
}

export function updateUserEmail(payload) {
  return {
    type: UPDATE_USER_EMAIL,
    payload
  }
}

export function updateUserName(payload) {
  // appEventEmitter.emit(AppEvents.username_update_success, { ...payload, app_version: APP_VERSION })
  return {
    type: UPDATE_USER_NAME,
    payload
  }
}

export function updateCredits(payload) {
  return {
    type: UPDATE_CREDITS,
    payload
  }
}

export function updateUserLocale(payload) {
  return {
    type: UPDATE_LOCALE,
    payload
  }
}

export function updateIntegrationStatus(payload) {
  return {
    type: UPDATE_INTEGRATION_STATUS,
    payload
  }
}


export function updateValidCardExists(payload) {
  return {
    type: UPDATE_VALID_CARD,
    payload
  }
}

export function updateUserLimits(payload) {
  return {
    type: UPDATE_USER_LIMITS,
    payload
  }
}

export function updateReccurCredits(payload) {
  return {
    type: UPDATE_RECCUR_CREDITS,
    payload
  }
}

export function updateNotificationSettings(payload) {
  return {
    type: UPDATE_NOTIFICATION_SETTINGS,
    payload
  }
}

export function updateLowCreditNotificationSettings(payload) {
  return {
    type: UPDATE_LOW_CREDIT_NOTIFICATION_SETTINGS,
    payload
  }
}

export function updateUserCurrentPlan(payload) {
  return {
    type: UPDATE_CURRENT_PLAN,
    payload
  }
}

export function updateUser(payload) {
  return {
    type: UPDATE_USER,
    payload
  }
}

export function showStripe(payload) {
  return {
    type: SHOW_STRIPE,
    payload
  }
}


export function updateUserAddress(payload) {
  return {
    type: UPDATE_USER_ADDRESS,
    payload
  }
}