export const CO_USER = 'userReducer'

export const BRAND = 'ClearoutPhone'
export const APP_URL = APP_API_URL
export const POPUP_WINDOW_WIDTH = "600"
export const POPUP_WINDOW_HEIGHT = "600"
export const CO_API = {
  Success: "success",
  Failed: "failed",
  Header: {
    'Content-Type': 'application/json'
  }
}

export const CLEAROUTPHONE_WEBSITE = {
  HOME: 'https://clearoutphone.io',
  PRIVACY_POLICY: 'https://clearoutphone.io/privacy-policy/',
  TERMS_AND_CONDITIONS: 'https://clearoutphone.io/terms-condition/',
  CONTACT_US: 'https://clearoutphone.io/contact-us/',
  API_DOC: 'https://docs.clearoutphone.io/',
  HELP: 'https://clearoutphone.io/help/',
  PHONE_VALIDATE_CSV_SAMPLE: 'https://clearoutphone.io/sample/csv/',
  PHONE_VALIDATE_XLSX_SAMPLE: 'https://clearoutphone.io/sample/xlsx/'
}

export const COPHONE_RESULT_FEILDS = ['status', 'line_type', 'carrier', 'location', 'country_name', 'country_timezone', 'country_code', 'international_format', 'local_format', 'e164_format', 'can_be_internationally_dialled', 'validated_on']

export const HIDE_SIDEBAR_FOR_ROUTES = ['/dashboard/pricing/', '/dashboard/integrations/', '/dashboard/integrations/hubspot/']

export const COPHONE_DISPLAY_RESULT_FEILDS = {
  carrier: 'Carrier',
  country_code: 'Country Code',
  country_name: 'Country Name',
  country_timezone: 'Country Timezone',
  e164_format: 'E164 Format',
  international_format: 'International Format',
  line_type: 'Line Type',
  location: 'Location',
  local_format: 'Local Format',
  status: 'Status',
  can_be_internationally_dialled: 'Can Be Internationally Dialled',
  validated_on: 'Validated On'
}

export const MAX_CREDIT_BUY = 5000000
export const MIN_CREDIT_BUY = 5000
export const PHONENUMNER_LINE_TYPES = {
  'fixed_line': 'Fixed Line',
  'mobile': 'Mobile',
  'fixed_line_or_mobile': 'Fixed Line Or Mobile',
  'toll_free': 'Toll Free',
  'premium_rate': 'Premium Rate',
  'shared_cost': 'Shared Cost',
  'voip': 'VoIP',
  'personal_number': 'Personel Number',
  'pager': 'Pager',
  'uan': 'UAN',
  'unknown': 'Unknown',
  'emergency': 'Emergency',
  'voicemail': 'VoiceMail',
  'short_code': 'ShortCode',
  'standard_rate': 'Standard Rate',
}



export const RESULTS_STATUS_DISPLAY = {
  running: 'Running',
  completed: 'Completed',
  queued: 'Queued',
  failed: 'Failed',
}

export const PAGINATION_FILTERS = [
  {
    "label": "5",
    "value": 5,
    "text": '5'
  }, {
    "label": "10",
    "value": 10,
    "text": '10'
  }, {
    "label": "25",
    "value": 25,
    "text": '25'
  }, {
    "label": "50",
    "value": 50,
    "text": '50'
  }, {
    "label": "100",
    "value": 100,
    "text": '100'
  },
]

export const RANGES_LIMIT = {
  "daily": {
    value: 'daily',
    label: 'Daily'
  },
  'life_time': {
    value: 'life_time',
    label: 'Lifetime'
  }
}

export const DISPLAY_RANGES_LIMIT = {
  "daily": {
    value: 'daily',
    label: 'Daily'
  },
  'lifetime': {
    value: 'lifetime',
    label: 'Lifetime'
  }
}

export const USER_ROLES = {
  "admin": {
    value: 'admin',
    label: 'Admin'
  },
  'analyst': {
    value: 'analyst',
    label: 'Executive'
  },
}
export const ROLE_FILTER = {
  "all": {
    value: '',
    label: 'All'
  },
  "admin": {
    value: 'admin',
    label: 'Admin'
  },
  "owner": {
    value: 'owner',
    label: 'Owner'
  },
  'analyst': {
    value: 'analyst',
    label: 'Executive'
  },
}

export const DISPLAY_USER_ROLES = {
  "admin": {
    value: 'admin',
    display_name: 'Admin'
  },
  'analyst': {
    value: 'analyst',
    display_name: 'Executive'
  },
  'owner': {
    value: 'owner',
    display_name: 'Owner'
  },
}

export const CHUNK_FILE_UPLOAD_ENDPOINT = 'v1/phonenumber/bulk_upload'
export const INVOICE_DOWNLOAD_ENDPOINT = 'v1/invoice/download?'

export const DATERANGE_PRESETS = {
  'date_range_presets': [
    'ps_today',
    'ps_yesterday',
    'ps_this_week_mon_today',
    'ps_last_7_days_including_today',
    'ps_last_week_mon_sun',
    'ps_this_month',
    'ps_last_month',
    'ps_this_year'
  ],
  date_range_presets_definitions: {
    ps_today: {
      display_name: 'Today',
      value: 'ps_today'
    },
    ps_yesterday: {
      display_name: 'Yesterday',
      value: 'ps_yesterday'
    },
    ps_last_7_days_including_today: {
      display_name: 'Last 7 days (Including Today)',
      value: 'ps_last_7_days_including_today'
    },
    ps_this_week_mon_today: {
      display_name: 'This week (Mon - Today)',
      value: 'ps_this_week_mon_today'
    },
    ps_last_week_mon_sun: {
      display_name: 'Last week (Mon - Sun)',
      value: 'ps_last_week_mon_sun'
    },
    ps_this_month: {
      display_name: 'This Month',
      value: 'ps_this_month'
    },
    ps_last_month: {
      display_name: 'Last Month',
      value: 'ps_last_month'
    },
    ps_this_year: {
      display_name: 'This Year',
      value: 'ps_this_year'
    },
    ps_last_24_hours: {
      display_name: 'Last 24 Hrs',
      value: 'ps_last_24_hours'
    }
  }
}



export const IMAGE_CARD_BRANDS = ['visa', 'mastercard', 'Discover', 'amex', 'Diners Club', 'JCB']

//result table display constants
export const ANALYSIS_TABLE_DISPLAY_ORDER = ['unknown', 'duplicate']
export const USERS_PERFORMANCE_STATS_TABLE_DISPLAY_ORDER = ['valid', 'invalid', 'unknown']
export const TOP_RESULTS_TABLE_DISPLAY_ORDER = ['valid', 'invalid']
export const DELIVERABILITY_TABLE_DISPLAY_ORDER = ['total', 'billable']
export const HISTORY_TABLE_DISPLAY_STATS = ['deliverability_score']

// result table display metrics based on actions(sync, analyse, verify)
export const SYNC_AND_EXPORT_CARD_RESULTS = ['total']
export const ANALYSE_CARD_RESULTS = ['total', 'duplicate']
export const ALL_RESULT_METRICS = ['valid', 'invalid', 'unknown', 'duplicate']

export const PIECHART_RESULT_ORDER = ['valid', 'invalid', 'unknown']
export const SUMMARY_TABLE_ORDER = ['valid', 'invalid', 'unknown']


export const RESULTS = {
  total: {
    display_name: 'Total',
    type: 'value'
  },
  valid: {
    display_name: 'Valid',
    type: 'percentage'
  },
  invalid: {
    display_name: 'Invalid',
    type: 'percentage'
  },
  unknown: {
    display_name: 'Unknown',
    type: 'percentage'
  },
  duplicate: {
    display_name: 'Duplicate',
    type: 'percentage'
  },
  billable: {
    display_name: 'Billable',
    type: 'value'
  },
  billable_credits: {
    display_name: 'Credits Used (Billable)',
    type: 'value'
  }
}

export const PERFORMANCE_STATS = {
  total: {
    display_name: 'Total',
    type: 'value'
  },
  valid: {
    display_name: 'Valid',
    type: 'value'
  },
  invalid: {
    display_name: 'Invalid',
    type: 'value'
  },
  unknown: {
    display_name: 'Unknown',
    type: 'value'
  },
  duplicate: {
    display_name: 'Duplicate',
    type: 'value'
  },
}

export const AVG_EMAIL_SENDING_COST = 0.015 // according to mailchimp

export const GRAPH_COLORS = ['#c6e377', '#f16f6f', '#75cac3', '#c0c0c0', '#970690']

//Version check and page reload related
export const HARD_RELOAD = 'hard_reload'
export const EMPTY_DATA_AND_HARD_RELOAD = 'empty_data_and_hard_reload'

export const DELIVERABILITY_SCORE_NOTE = "A score to measure your reach to the client's inbox. It is calculated on the basis of multiple factors like the historical sender score, no. of times domain has been blacklisted, role-based, etc."

export const BILLABLE_EMAIL_NOTE = "All valid, invalid and catch-all email addresses are considered billable. Duplicate and unknown/undetermined emails are not billable and the credits for the same will not be deducted."
export const BILLABLE_PHONE_NOTE = "All valid, invalid phone numbers are considered billable."
export const SELECT_DEFAULT_COUNTRY_NOTE = "The default country will be used if the phone number does not prefix with country code"

export const MIN_CREDITS_AVAILABLE = 500
export const MAX_EMAIL_ADDRESSES_ALLOWED_QUICK_VALIDATION = 10


export const SUPPORT_EMAIL = 'us@clearoutphone.io'

export const PRICING_MAX_FOR_MILLION = 1000001
export const PRICING_MAX_VALUE = 10000000
export const LIST_LOAD_TIME = 10000
export const USERNAME_CHAR_LIMIT = 30
export const PASSWORD_CHAR_LIMIT = 30
export const TIMER_LIMIT = 30
export const THOUSAND_MILLI_SECONDS = 1000

export const VERIFIED_TYPES = {
  'verified': {
    value: 'verified',
    label: 'Verified'
  },
  'non_verified': {
    value: 'non_verified',
    label: 'Non-Verified'
  },
  'in_progress': {
    value: 'in_progress',
    label: 'In Progress'
  },
}
export const LIST_TYPES = {
  mailchimp: {
    value: 'mailchimp',
    label: 'Mailchimp'
  },
  active_campaign: {
    value: 'active_campaign',
    label: 'ActiveCampaign'
  },
  moosend: {
    value: 'moosend',
    label: 'Moosend'
  },
  hubspot: {
    value: 'hubspot',
    label: 'Hubspot'
  },
  upload: {
    value: 'upload',
    label: 'Upload'
  },
  leads: {
    value: 'leads',
    label: 'Leads'
  },
  all: {
    label: 'All',
    value: 'all'
  }
}

export const LIST_ACTIONS = {
  sync: {
    value: 'sync',
    display: 'Sync',
    progress: 'Syncing',
    queued: ' Queued',
    history: 'synced'
  },
  verify: {
    value: 'verify',
    display: 'Verify',
    progress: 'Verifying',
    queued: ' Queued',
    history: 'verified'
  },
  pnv: {
    value: 'pnv',
    display: 'Phone Number Validation',
    progress: 'Validating',
    queued: ' Queued',
    history: 'validated'
  },
  analyse: {
    value: 'analyse',
    display: 'Analyse',
    progress: 'Analyzing',
    queued: ' Queued',
    history: 'analysed'
  },
  export: {
    value: 'export',
    display: 'Export',
    progress: 'Exporting',
    queued: ' Queued',
    history: 'exported'
  },
  failed: {
    value: 'failed',
    display: 'Failed',
    progress: 'Failing',
    queued: ' Queued',
    history: 'failed'
  }
}

export const FILE_TYPE = {
  csv: 'CSV',
  xlsx: 'XLSX'
}

export const QUICK_VALIDATION_TIMEOUT = 110000
export const MAILCHIMP_UNSUBSCRIBE = ['invalid', 'unknown']
// export const MAILCHIMP_APPEND = ['status', 'reason', 'score', 'suggested_email_address', 'disposable', 'free_account', 'role_account']
export const EXPORT_APPEND = ['status', 'carrier', 'country_code', 'country_name', 'country_timezone', 'e164_format', 'international_format', 'line_type', 'local_format', 'location', 'can_be_internationally_dialled', 'verified_on', 'time_taken']
// export const DOWNLOAD_FILE_APPEND_COLUMNS = ['status', 'reason', 'score', 'suggested_email_address', 'greylist_count', 'disposable', 'free_account', 'role_account', 'account', 'domain', 'verified_on', 'time_taken']
export const DOWNLOAD_FILE_APPEND_COLUMNS = ['status', 'carrier', 'country_code', 'country_name', 'country_timezone', 'e164_format', 'international_format', 'line_type', 'local_format', 'location', 'can_be_internationally_dialled', 'verified_on', 'time_taken']

export const DOWNLOAD_VALIDATION_FILTER = ['valid', 'invalid', 'unknown']
export const HELP_TEXT_STATUS = ['Total', 'Billable', 'Unknown']

export const HELP_TEXT_STATUS_DEFS = {
  total: {
    defs: 'Total number of phone numbers in the list including duplicate',
  },
  billable: {
    defs: 'Billable represent the number of credits required, this will be sum of Valid and Invalid phone numbers. Duplicate and Unknown won\'t be considered for billable ',
  },
  unknown: {
    defs: 'If phone number status were not able to determine through validation process it will be marked as Unknown and credit won\'t be used nor consider in billable',
  },
  duplicate: {
    defs: 'Only the first occurrence of the duplicate phone number row will be considered in the result file.'
  }
}



//applied with status filter
export const VALIDATION_FILTERS = {
  valid: {
    value: 'valid',
    name: 'Valid - Phone numbers'
  },
  invalid: {
    value: 'invalid',
    name: 'Invalid - Phone numbers'
  },
  unknown: {
    value: 'unknown',
    name: 'Unknown - Phone numbers'
  },
}
export const APPEND_COLUMNS = {
  status: {
    value: 'status',
    name: 'Status'
  },
  reason: {
    value: 'reason',
    name: 'Reason'
  },
  score: {
    value: 'score',
    name: 'Score'
  },
  greylist_count: {
    value: 'greylist_count',
    name: 'Greylist Count'
  },
  verified_on: {
    value: 'verified_on',
    name: 'Verified On'
  },
  suggested_email_address: {
    value: 'suggested_email_address',
    name: 'Suggested Email Address'
  },
  disposable: {
    value: 'disposable',
    name: 'Disposable'
  },
  role_account: {
    value: 'role_account',
    name: 'Role Account'
  },
  free_account: {
    value: 'free_account',
    name: 'Free Account'
  },
  account: {
    value: 'account',
    name: 'Account'
  },
  domain: {
    value: 'domain',
    name: 'Domain'
  },
  time_taken: {
    value: 'time_taken',
    name: 'Time Taken'
  }
}

export const EXPORT_APPEND_COLUMNS = {
  status: {
    value: 'status',
    name: 'Status'
  },
  carrier: {
    value: 'carrier',
    name: 'Carrier'
  },
  country_code: {
    value: 'country_code',
    name: 'Country Code'
  },
  country_name: {
    value: 'country_name',
    name: 'Country Name'
  },
  country_timezone: {
    value: 'country_timezone',
    name: 'Country Timezone'
  },
  e164_format: {
    value: 'e164_format',
    name: 'E164 Format'
  },
  international_format: {
    value: 'international_format',
    name: 'International Format'
  },
  line_type: {
    value: 'line_type',
    name: 'Line Type'
  },
  local_format: {
    value: 'local_format',
    name: 'Local Format'
  },
  location: {
    value: 'location',
    name: 'Location'
  },
  can_be_internationally_dialled: {
    value: 'can_be_internationally_dialled',
    name: 'Can Be Internationaly Dialed'
  },
  verified_on: {
    value: 'verified_on',
    name: 'Verified On'
  },
  time_taken: {
    value: 'time_taken',
    name: 'Time Taken'
  }
}

export const UNSUBSCRIBE_COLUMNS = {
  unknown: {
    value: 'unknown',
    name: 'Unknown'
  },
  invalid: {
    name: 'Invalid',
    value: 'invalid'
  },
}
// export const STATUS_FILTER_HELP_TEXT="By applying status filter will generate the result file with the email addresses that match the chosen status values "
export const STATUS_FILTER_HELP_TEXT = "Apply this filter to include verified email addresses with a particular status from - Valid, Invalid, Unknown and Catch all."

export const DOWNLOAD_REQUEST_LINE_POLLING_API_ENDPOINT = 'v1/download/result/status?queue_id='
export const LEADS_INFO = "Lead Finder is a great tool to find prospective clients for your B2B business. With the help of this tool you can extract a list of prospects that you can approach. The Lead finder provides you with the prospect's name, company address, email address and phone number."
export const APPEND_HELP_TEXT = "All columns selected for append will be appended with 'CO' in result file. Eg: Status will be 'CO Status'."
export const MAILCHIMP_APPEND_HELP_TEXT = "All columns selected for append will be appended with 'Clearoutphone' in result file. Eg: Status will be 'Clearoutphone Status'"
export const UNSUBSCRIBE_HELP_TEXT = "Selecting Unsubscribe as part of export will unsubscribe those contacts in the chosen list"
export const DAILY_VERIFY_LIMIT_TEXT = 'Indicates how many verify request can be made per day.'

export const ALLOWED_DAILY_VERIFY_LIMIT = 'Metric to know how many verify request are allowed per day.'

export const AVAILABLE_DAILY_VERIFY_LIMIT = 'Metric to know how many verify request are remaining per day.'

export const INTEGRATION_PLATFORMS = ['mailchimp', 'active_campaign', 'moosend', 'hubspot']

export const ACCOUNT_NAME = "Unique name to identify this account"

export const ACTIVE_CAMPAIGN_URL = "Login to ActiveCampaign and navigate to settings -> Developer -> URL"

export const ACTIVE_CAMPAIGN_KEY = "Login to ActiveCampaign and navigate to settings -> Developer -> Key"

export const PRICING_RESTRICT_ROLES = ['analyst', 'admin']
export const ADMIN_RESTRICT_ROLES = ['owner', 'admin']
export const OWNER_RESTRICT_ROLES = ['owner']
export const OWNER_ACCESS = ['pricing', 'billing']
export const MOOSEND_API_KEY = "Login to Moosend and navigate to Account -> Api Key"
export const UNLIMITED_CREDIT_HELPTEXT = "No any specific credit based usage limit"
export const UNLIMITED_ALLOWED_DAILY_LIMIT_HELPTEXT = "No any specific daily usage limit"
export const UNLIMITED_REMAINING_DAILY_LIMIT_HELPTEXT = "No any specific daily usage limit for today"
export const ALLOWED_DAILY_LIMIT_HELPTEXT = "Any update in allowed daily limit value will be applied start of following day"
export const REMAINING_DAILY_LIMIT_HELPTEXT = "Any update in remaining daily limit will be applied immediately and will be reset to allowed daily limit on start of following day"

export const CURRENCY = {
  USD: {
    flag: require("../../src/static/svg/us_flag.svg"),
    symbol: '$',
    name: 'USD',
    locale: 'en-US'
  },
  INR: {
    flag: require("../../src/static/svg/in_flag.svg"),
    symbol: '₹',
    name: 'INR',
    locale: 'en-IN'
  }
}

export const COUPON_TYPE = {
  discount: 'discount',
  standard: 'standard',
  bonus: 'bonus'
}

export const SIGNUP_USER_SOURCES = [
  {
    label: 'Google Search',
    value: 'Google Search',
    icon: "fa fa-google"
  },
  {
    label: 'Bing Search',
    value: 'Bing Search',
    icon: "fa fa-search"
  },
  {
    label: 'Facebook',
    value: 'Facebook',
    icon: "fa fa-facebook-square"
  },
  {
    label: 'Twitter',
    value: 'Twitter',
    icon: "fa fa-twitter-square"
  },
  {
    label: 'Quora',
    value: 'Quora',
    icon: "fa fa-quora"
  },
  {
    label: 'LinkedIn',
    value: 'LinkedIn',
    icon: "fa fa-linkedin-square"
  },
  {
    label: 'Youtube',
    value: 'Youtube',
    icon: "fa fa-youtube-square"
  },
  {
    label: 'Blog',
    value: 'Blog',
    icon: "fa fa-rss-square"
  },
  {
    label: 'Online Ads',
    value: 'Online Ads',
    icon: "fa fa-laptop"
  },
  {
    label: 'Recommendation from Friends/Family',
    value: 'Recommendation from Friends/Family',
    icon: "fa fa-users"
  },
  {
    label: 'Recommendation from Work, Co-Workers or Customers',
    value: 'Recommendation from Work, Co-Workers or Customers',
    icon: "fa fa-briefcase"
  },
  {
    label: 'Team Invite',
    value: 'Team Invite',
    icon: "fa fa-envelope"
  },
  {
    label: 'Others : (Please Specify)',
    value: 'Others : (Please Specify)',
    icon: ""
  }
]

export const CANCEL_REASONS = [
  {
    label: "I don’t understand how to use your product",
    value: "I don’t understand how to use your product"
  },
  {
    label: "It’s too expensive",
    value: "It’s too expensive"
  },
  {
    label: "I found another product that I like better",
    value: "I found another product that I like better"
  },
  {
    label: "I don’t use it enough",
    value: "I don’t use it enough"
  },
  {
    label: "Some features I need are missing",
    value: "Some features I need are missing"
  },
  {
    label: "Not realizing the value proposition",
    value: "Not realizing the value proposition"
  },
  {
    label: "Poor customer support",
    value: "Poor customer support"
  }
  ,
  {
    label: 'Others : (Please Specify)',
    value: 'Others : (Please Specify)',
  }
]

export const SUBS_PRICING_DISCOUNT_TEXT = 'Pay Monthly / Annual - 20% less than One Time'
export const SUBS_PRICING_MONTHLY_DISCOUNT_TEXT = 'Get 20% discount for the price when you pay annually.'
export const HEADER_SUBS_PRICING_DISCOUNT_TEXT = 'Pay 20% less than One Time (Pay-as-you-go), Unused credits never expire'

export const UTM_PARAMS = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_network', 'utm_device', 'utm_adgroup', 'utm_creative', 'utm_placement', 'utm_keyword', 'utm_matchtype', 'utm_ga_loc_physical_ms', 'adposition']

export const USER_SOURCE = {
  INSTANT_PHONENUMBER_VALIDATE: 'instant_phonenumber_validate'
}

export const ORGANIZATION_NAME_CHAR_LIMIT = 50

export const SIGNUP_USER_INDUSTRY = [
  {
    label: 'Education / E-learning',
    value: 'Education / E-learning',
  },
  {
    label: 'Email Service Provider',
    value: 'Email Service Provider',
  },
  {
    label: 'Entertainment',
    value: 'Entertainment',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },
  {
    label: 'Gaming / Gambling Casinos',
    value: 'Gaming / Gambling Casinos',
  },
  {
    label: 'Government',
    value: 'Government',
  },
  {
    label: 'Hospital / Healthcare',
    value: 'Hospital / Healthcare',
  },
  {
    label: 'Hospitality / Travel',
    value: 'Hospitality / Travel',
  },
  {
    label: 'Information / Data Services',
    value: 'Information / Data Services',
  },
  {
    label: 'Marketing / Advertising',
    value: 'Marketing / Advertising',
  },
  {
    label: 'Media',
    value: 'Media',
  },
  {
    label: 'Real estate / Mortgage',
    value: 'Real estate / Mortgage',
  },
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Software / Hardware',
    value: 'Software / Hardware',
  },
  {
    label: 'Technology',
    value: 'Technology',
  },
  {
    label: 'Telecommunication / Wireless',
    value: 'Telecommunication / Wireless',
  },
  {
    label: 'Transportation',
    value: 'Transportation',
  },
  {
    label: 'Others : (Please Specify)',
    value: 'Others : (Please Specify)',
  }
]

export const SIGNUP_USER_JOB_TITLE = [
  {
    label: 'CEO / President / Owner / General Manager / Partner',
    value: 'CEO / President / Owner / General Manager / Partner',
  },
  {
    label: 'Vice President / Assistant Vice President',
    value: 'Vice President / Assistant Vice President',
  },
  {
    label: 'CFO / Treasurer / Controller',
    value: 'CFO / Treasurer / Controller',
  },
  {
    label: 'Director / Assistant Director / Department Head',
    value: 'Director / Assistant Director / Department Head',
  },
  {
    label: 'Manager / Assistant Manager',
    value: 'Manager / Assistant Manager',
  },
  {
    label: 'Professional (salaried)',
    value: 'Professional (salaried)',
  },
  {
    label: 'Technical (hourly)',
    value: 'Technical (hourly)',
  },
  {
    label: 'Small Business Owner',
    value: 'Small Business Owner',
  },
  {
    label: 'Doctor / Physician',
    value: 'Doctor / Physician',
  },
  {
    label: 'Developer / Programmer',
    value: 'Developer / Programmer',
  },
  {
    label: 'Real estate / Mortgage',
    value: 'Real estate / Mortgage',
  },
  {
    label: 'Lawyer',
    value: 'Lawyer',
  },
  {
    label: 'Freelancer',
    value: 'Freelancer',
  },
  {
    label: 'Technology',
    value: 'Technology',
  },
  {
    label: 'Sales Representative',
    value: 'Sales Representative',
  },
  {
    label: 'Marketing / Communications',
    value: 'Marketing / Communications',
  },
  {
    label: 'Customer Service',
    value: 'Customer Service',
  },
  {
    label: 'Human Resources',
    value: 'Human Resources',
  },
  {
    label: 'Educator',
    value: 'Educator',
  },
  {
    label: 'Clerical / Administrative Support',
    value: 'Clerical / Administrative Support',
  },
  {
    label: 'Don\'t work / Student',
    value: 'Don\'t work / Student',
  },
  {
    label: 'Others : (Please Specify)',
    value: 'Others : (Please Specify)',
  }
]

export const NEWSLETTER_SUBSCRIPTION_TEXT = 'I\'d like to subscribe to the "ClearoutPhone Newsletter" to stay on top of mobile marketing, product updates, and special offers.'

export const MAX_BULK_UPLOAD_FILE_NAME_LENGTH = 200

export const PAYMENT_GATEWAYS = {
  PAYPAL: 'paypal',
  STRIPE: 'stripe'
}

export const FRESHCHAT_CONFIG = {
  headerProperty: {
    backgroundColor: '#6200EA',
    borderColor: 'transparent #6200EA transparent transparent !important',
    foregroundColor: '#fff'
  }
}

export const ALERT_MODAL = 'modal_alert'
export const ALERT_MODAL_PRIORITY = 100
export const VERSION_MODAL = 'version_modal'
export const VERSION_MODAL_PRIORITY = 3
export const CREDIT_MODAL = 'credit_modal'
export const CREDIT_MODAL_PRIORITY = 2
export const WELCOME_MODAL = 'welcome_modal'
export const WELCOME_MODAL_PRIORITY = 1

export const DETAILS_FORM = {
  CARD: 'card',
  ADDRESS: 'address',
  ADDRESS_AND_CARD: 'address_and_card'
}

export const FREEMIUM_PRODUCT_ID = '241095070400180595110500'